
.card-login{
    max-width: 18em;
}
  
.btnDigito{
    width: 100%;
    font-Size: 1.8em;
    padding: 0.7em 0;
}

.img-login{
    padding: 1em 1em;
}

.nombreEmpleado{
    font-size: 2.2em;
    font-weight: bold;
}

.btn-ausencia{
    padding: 1.2em 0em;
    font-size: 1.1em;
}

.btn-salida-ppal{
    margin-bottom: 1.6em;
}
.btn-salida-ausencia{
    margin-bottom: 1.1em;
}

@media (max-width: 767px){
    .card-login{
        max-width: 12em;
    }
    .btnDigito{
        font-Size: 1.5em;
        padding: 0.7em 0;
    }
    .nombreEmpleado{
        font-size: 1.8em;
        font-weight: bold;
    }
}

@media (max-width: 575px){
    .card-login{
        max-width: 100%;
    }
    .img-login{
        padding: 0em 0.5em;
    }
    .btnDigito{
        font-Size: 1.2em;
        padding: 0.7em 0;
    }
    .btn-ausencia{
        padding: 0.8em 0em;
        font-size: 0.9em;
    }
    .nombreEmpleado{
        font-size: 1em;
        font-weight: bold;
    }
    .btn-salida-ppal{
        margin-bottom: 0.8em;
    }
    .btn-salida-ausencia{
        margin-bottom: 0.5em;
    }
}


  
