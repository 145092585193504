.App {
  text-align: center;
  overflow-x: hidden;
}


.div-pass{
  /*text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;*/
}

